import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Image from 'gatsby-image/withIEPolyfill'
import React, { useContext, useEffect, useState } from 'react'

import GallerySection from '../../components/CarryAll/GallerySection'
import Layout from '../../components/Layout'
import PersonliseModal from '../../components/Personalisation'
import SelectorSection from '../../components/SelectorSection'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import usePersonalisationParams from '../../helper/usePersonalisationParams'
import useProductData from '../../helper/useProductData'
import useSelectedColor from '../../helper/useSelectedColor'
import useTracking from '../../helper/useTracking'
import { CarryContainer } from '../../styles/suitcases'
import Panels from './Panels'
import PetPersonliseModal from '../../components/PersonalisationDog'


function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const colorNamesText = [
  'Navy', //navy
  'Black', //charcoal
  'Forest', //forrest
  'Sand', //nude
  'Clay', //burgundy
  'Shadow', //monochrome
  'Yellow',
  'Blue',
  'Magenta',
]

const colorNamesTextParams = [
  'Navy',
  'Charcoal',
  'Forest',
  'Sand',
  'Clay',
  'Shadow',
  'Yellow',
  'Blue',
  'Magenta',
]

const count = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']

const skus = [
  'CME21-NAV',
  'CME22-CHA',
  'CME23-FOR',
  'CME24-SAN',
  'CME25-CLA',
  'CME26-SHA',
  'CME27-YEL',
  'CME28-BLU',
]

const CarryMe = ({ data, pageContext, location }) => {
  const context = useContext(StoreContext)

  let [personaliseOpen, setPersonaliseOpen] = useState(false)
  let [labelData, setLabelData] = useState({})

  let [petLabelData, setPetLabelData] = useState({})
  let [petPersonaliseOpen, setPetPersonalisationOpen] = useState(undefined)
  const petPersonalised = isEmpty(petLabelData)

  const personalised = isEmpty(labelData)

  const {
    title,
    price,
    hasPersonalisation,
    freePersonalisation,
    description,
    overview,
    specs,
    reviewId,
    shopifyIds,
    fbIDs,
    oosData,
  } = useProductData(data.allCockpitProducts, !(!personalised || !petPersonalised))

  const [selectedColor, setSelectedColor] = useSelectedColor(
    location.search,
    0,
    colorNamesTextParams.map((val) => val.toLowerCase()),
    oosData
  )
  const [strapColor, setStrapColor] = useSelectedColor(
    location.search,
    0,
    colorNamesTextParams.map((val) => val.toLowerCase()),
    oosData
  )
  useEffect(() => {
    setStrapColor(selectedColor)
  }, [selectedColor])
  const [trackAddToCart, updateItem] = useTracking()

  const images_names = colorNamesText.map((color_val) =>
    count.map((count_val) => {
      return `${color_val}${count_val}${count_val === '2' ? selectedColor : ''}`
    })
  )
  const images = images_names.map((second) => [
    ...second.map((name) => data[name]),
  ])

  useEffect(() => {
    updateItem({
      title: 'Carry Me',
      id: fbIDs[selectedColor] && fbIDs[selectedColor].split('_')[2],
      price: parseInt((price?.match(/\d+/g) || []).join('')),
      url: 'https://july.com/travel-bags/carry-me',
      imageUrl: '',
      sku: skus[selectedColor],
      fb_id: fbIDs[selectedColor],
      loaded: true,
      variant: colorNamesText[selectedColor],
    })
  }, [selectedColor, fbIDs])

  const handleAddToCart = async (e) => {
    e.preventDefault()

    let carryMeVarients = shopifyIds

    let backendLabelData = {}

    if (!personalised) {
      backendLabelData = { ...labelData, font: labelData.font.toLowerCase() }
    }

    if (!petPersonalised) {
      backendLabelData = { 
        position: petLabelData.position,
        pets: btoa(JSON.stringify(petLabelData.pets.map(pet => {
          return {
            name: pet.name,
            signedUrl: pet.signedUrl
          }
        })))
      }
    }



    await context.addVariantToCart(
      [carryMeVarients[selectedColor]],
      1,
      {
        ...backendLabelData,
      },
      undefined,
      [0]
    )

    // mixpanel.track('Carry Me added to cart')
    fbq(context.currency, 'AddToCart', {
      content_name: 'Carry Me',
      content_category: 'Luggage',
      content_ids: [fbIDs[selectedColor]],
      content_type: 'product',
      value: !personalised ? 85 : 65,
      currency:
        context.language
          .replace('root', 'au')
          .replace('global', 'us')
          .toUpperCase() + 'D',
    })

    trackAddToCart()
    context.openCart()
  }

  return (
    <>
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.fixed?.srcWebp
        }
        productData={{
          name: 'Carry Me',
          imageSrc:
            'https://cdn.shopify.com/s/files/1/0047/1639/6613/products/03-FOR.jpg?v=1574928837',
          description: `The July Carry Me. The perfect bag to take when you don't need a bag at all. The right size for all the essentials, protected in our strong signature shell. With a detachable nylon shoulder strap, wear it however you'd like. Try for 100 days. 5 year warranty. `,
          price: parseInt((price?.match(/\d+/g) || []).join('')),
          sku: 'CME01-NAV',
          handle: 'carry-me',
        }}
        reviewData={{
          value: '5',
          count: '24',
        }}
      />
      <div
        style={{
          display: personaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {personaliseOpen && (
          <PersonliseModal
            handleSave={(data) => {
              setPersonaliseOpen(false)
              setLabelData(data)
            }}
            handleClose={() => setPersonaliseOpen(false)}
            initialValues={labelData}
            selectedCaseColor={selectedColor}
            setSelectedCaseColor={setSelectedColor}
            selectedCase="Me-V2"
          />
        )}
      </div>
      <div
        style={{
          display: petPersonaliseOpen ? 'block' : 'none',
          height: '100vh',
        }}
      >
        {petPersonaliseOpen && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              zIndex: 999999,
            }}
          >
            <PetPersonliseModal
              handleSave={(data) => {
                setPetPersonalisationOpen(false)
                setPetLabelData(data)
              }}
              handleClose={() => setPetPersonalisationOpen(false)}
              initialValues={petLabelData}
              selectedCaseColor={selectedColor}
              setSelectedCaseColor={setSelectedColor}
              selectedCase="Me-V2"
            />
          </div>
        )}
      </div>

      <div style={{ display: personaliseOpen ? 'none' : 'block' }}>
        <Layout
          facebookCaseId={fbIDs[selectedColor]}
          location="product"
          context={context}
        >
          <CarryContainer>
            <LandingContainer>
              <GallerySection
                selectedColor={selectedColor}
                images={images[selectedColor]}
                videos={[]}
                campaign={true}
                defaultImage={1}
                changeImageTo={strapColor}
                hasPersonalisation={true}
              />
              <SelectorSection
                name={title}
                price={price}
                reviewId={reviewId}
                freePersonalisation={freePersonalisation}
                oosData={oosData}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                outline={description}
                outlineSub={overview}
                details={specs}
                handleBefore={() => {}}
                handleAddToCart={handleAddToCart}
                isLuggage={true}
                noSet={true}
                setPersonaliseOpen={setPersonaliseOpen}
                labelData={labelData}
                personalised={personalised}

                setPetPersonalisationOpen={setPetPersonalisationOpen}
                petLabelData={petLabelData}
                petPersonalised={petPersonalised}
  
              />
            </LandingContainer>
            {/* {
              reviewId && (
                <Reviews productId={reviewId} />
              )
            } */}
          </CarryContainer>
          <Panels setPersonaliseOpen={setPersonaliseOpen} />
        </Layout>
      </div>
    </>
  )
}
export const query = graphql`
  query {
    SEO: cockpitPage(
      cannonical_url: { value: { eq: "/travel-bags/carry-me" } }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }

      TEST_banner_image {
        value {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

    allCockpitProducts(
      filter: { handle: { value: { eq: "/travel-bags/carry-me" } } }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    strap: allCockpitProducts(
      filter: {
        handle: { value: { eq: "/travel-accessories/carry-me-bag-straps" } }
      }
    ) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          price {
            value
          }
          price_personalised {
            value
          }
          published {
            value
          }
          has_personalisation {
            value
          }
          free_personalisation {
            value
          }
          description {
            value
          }
          overview {
            value
          }
          specs {
            value
          }
          review_id {
            value
          }
          shopify_ids {
            value {
              data
            }
          }
          fb_ids {
            value {
              data
            }
          }
          lang
          oosDetails {
            value {
              data
            }
          }
        }
      }
    }

    Camp1: file(relativePath: { eq: "carry-me-v2/ecom/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Black1: file(relativePath: { eq: "carry-me-v2/ecom/black/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black20: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/nav.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black21: file(relativePath: { eq: "carry-me-v2/ecom/black/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black22: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/for.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black23: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/san.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black24: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/cla.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black25: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/sha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black26: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/yel.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black27: file(
      relativePath: { eq: "carry-me-v2/ecom/black/straps/blu.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black3: file(relativePath: { eq: "carry-me-v2/ecom/black/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black4: file(relativePath: { eq: "carry-me-v2/ecom/black/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black5: file(relativePath: { eq: "carry-me-v2/ecom/black/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black6: file(relativePath: { eq: "carry-me-v2/ecom/black/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black7: file(relativePath: { eq: "carry-me-v2/ecom/black/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black8: file(relativePath: { eq: "carry-me-v2/ecom/black/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black9: file(relativePath: { eq: "carry-me-v2/ecom/black/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black10: file(relativePath: { eq: "carry-me-v2/ecom/black/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Black11: file(relativePath: { eq: "carry-me-v2/ecom/black/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Clay1: file(relativePath: { eq: "carry-me-v2/ecom/clay/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay20: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/nav.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay21: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/cha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay22: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/for.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay23: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/san.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay24: file(relativePath: { eq: "carry-me-v2/ecom/clay/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay25: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/sha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay26: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/yel.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay27: file(relativePath: { eq: "carry-me-v2/ecom/clay/straps/blu.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay3: file(relativePath: { eq: "carry-me-v2/ecom/clay/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay4: file(relativePath: { eq: "carry-me-v2/ecom/clay/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay5: file(relativePath: { eq: "carry-me-v2/ecom/clay/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay6: file(relativePath: { eq: "carry-me-v2/ecom/clay/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay7: file(relativePath: { eq: "carry-me-v2/ecom/clay/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay8: file(relativePath: { eq: "carry-me-v2/ecom/clay/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay9: file(relativePath: { eq: "carry-me-v2/ecom/clay/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay10: file(relativePath: { eq: "carry-me-v2/ecom/clay/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Clay11: file(relativePath: { eq: "carry-me-v2/ecom/clay/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Forest1: file(relativePath: { eq: "carry-me-v2/ecom/forest/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest20: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/nav.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest21: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/cha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest22: file(relativePath: { eq: "carry-me-v2/ecom/forest/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest23: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/san.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest24: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/cla.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest25: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/sha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest26: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/yel.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest27: file(
      relativePath: { eq: "carry-me-v2/ecom/forest/straps/blu.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest3: file(relativePath: { eq: "carry-me-v2/ecom/forest/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest4: file(relativePath: { eq: "carry-me-v2/ecom/forest/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest5: file(relativePath: { eq: "carry-me-v2/ecom/forest/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest6: file(relativePath: { eq: "carry-me-v2/ecom/forest/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest7: file(relativePath: { eq: "carry-me-v2/ecom/forest/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest8: file(relativePath: { eq: "carry-me-v2/ecom/forest/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest9: file(relativePath: { eq: "carry-me-v2/ecom/forest/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest10: file(relativePath: { eq: "carry-me-v2/ecom/forest/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Forest11: file(relativePath: { eq: "carry-me-v2/ecom/forest/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Navy1: file(relativePath: { eq: "carry-me-v2/ecom/navy/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy20: file(relativePath: { eq: "carry-me-v2/ecom/navy/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy21: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/cha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy22: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/for.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy23: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/san.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy24: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/cla.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy25: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/sha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy26: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/yel.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy27: file(relativePath: { eq: "carry-me-v2/ecom/navy/straps/blu.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy3: file(relativePath: { eq: "carry-me-v2/ecom/navy/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy4: file(relativePath: { eq: "carry-me-v2/ecom/navy/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy5: file(relativePath: { eq: "carry-me-v2/ecom/navy/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy6: file(relativePath: { eq: "carry-me-v2/ecom/navy/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy7: file(relativePath: { eq: "carry-me-v2/ecom/navy/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy8: file(relativePath: { eq: "carry-me-v2/ecom/navy/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy9: file(relativePath: { eq: "carry-me-v2/ecom/navy/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy10: file(relativePath: { eq: "carry-me-v2/ecom/navy/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Navy11: file(relativePath: { eq: "carry-me-v2/ecom/navy/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Sand1: file(relativePath: { eq: "carry-me-v2/ecom/sand/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand20: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/nav.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand21: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/cha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand22: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/for.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand23: file(relativePath: { eq: "carry-me-v2/ecom/sand/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand24: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/cla.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand25: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/sha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand26: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/yel.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand27: file(relativePath: { eq: "carry-me-v2/ecom/sand/straps/blu.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand3: file(relativePath: { eq: "carry-me-v2/ecom/sand/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand4: file(relativePath: { eq: "carry-me-v2/ecom/sand/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand5: file(relativePath: { eq: "carry-me-v2/ecom/sand/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand6: file(relativePath: { eq: "carry-me-v2/ecom/sand/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand7: file(relativePath: { eq: "carry-me-v2/ecom/sand/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand8: file(relativePath: { eq: "carry-me-v2/ecom/sand/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand9: file(relativePath: { eq: "carry-me-v2/ecom/sand/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand10: file(relativePath: { eq: "carry-me-v2/ecom/sand/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Sand11: file(relativePath: { eq: "carry-me-v2/ecom/sand/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Shadow1: file(relativePath: { eq: "carry-me-v2/ecom/shadow/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow20: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/nav.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow21: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/cha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow22: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/for.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow23: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/san.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow24: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/cla.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow25: file(relativePath: { eq: "carry-me-v2/ecom/shadow/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow26: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/yel.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow27: file(
      relativePath: { eq: "carry-me-v2/ecom/shadow/straps/blu.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow3: file(relativePath: { eq: "carry-me-v2/ecom/shadow/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow4: file(relativePath: { eq: "carry-me-v2/ecom/shadow/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow5: file(relativePath: { eq: "carry-me-v2/ecom/shadow/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow6: file(relativePath: { eq: "carry-me-v2/ecom/shadow/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow7: file(relativePath: { eq: "carry-me-v2/ecom/shadow/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow8: file(relativePath: { eq: "carry-me-v2/ecom/shadow/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow9: file(relativePath: { eq: "carry-me-v2/ecom/shadow/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow10: file(relativePath: { eq: "carry-me-v2/ecom/shadow/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Shadow11: file(relativePath: { eq: "carry-me-v2/ecom/shadow/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Yellow1: file(relativePath: { eq: "carry-me-v2/ecom/yellow/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow20: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/nav.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow21: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/cha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow22: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/for.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow23: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/san.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow24: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/cla.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow25: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/sha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow26: file(relativePath: { eq: "carry-me-v2/ecom/yellow/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow27: file(
      relativePath: { eq: "carry-me-v2/ecom/yellow/straps/blu.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow3: file(relativePath: { eq: "carry-me-v2/ecom/yellow/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow4: file(relativePath: { eq: "carry-me-v2/ecom/yellow/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow5: file(relativePath: { eq: "carry-me-v2/ecom/yellow/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow6: file(relativePath: { eq: "carry-me-v2/ecom/yellow/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow7: file(relativePath: { eq: "carry-me-v2/ecom/yellow/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow8: file(relativePath: { eq: "carry-me-v2/ecom/yellow/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow9: file(relativePath: { eq: "carry-me-v2/ecom/yellow/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow10: file(relativePath: { eq: "carry-me-v2/ecom/yellow/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Yellow11: file(relativePath: { eq: "carry-me-v2/ecom/yellow/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Blue1: file(relativePath: { eq: "carry-me-v2/ecom/blue/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue20: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/nav.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue21: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/cha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue22: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/for.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue23: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/san.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue24: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/cla.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue25: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/sha.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue26: file(relativePath: { eq: "carry-me-v2/ecom/blue/straps/yel.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue27: file(relativePath: { eq: "carry-me-v2/ecom/blue/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue3: file(relativePath: { eq: "carry-me-v2/ecom/blue/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue4: file(relativePath: { eq: "carry-me-v2/ecom/blue/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue5: file(relativePath: { eq: "carry-me-v2/ecom/blue/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue6: file(relativePath: { eq: "carry-me-v2/ecom/blue/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue7: file(relativePath: { eq: "carry-me-v2/ecom/blue/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue8: file(relativePath: { eq: "carry-me-v2/ecom/blue/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue9: file(relativePath: { eq: "carry-me-v2/ecom/blue/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue10: file(relativePath: { eq: "carry-me-v2/ecom/blue/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Blue11: file(relativePath: { eq: "carry-me-v2/ecom/blue/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    Magenta1: file(relativePath: { eq: "carry-me-v2/ecom/magenta/1.jpg" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta20: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/nav.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta21: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/cha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta22: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/for.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta23: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/san.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta24: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/cla.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta25: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/sha.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta26: file(
      relativePath: { eq: "carry-me-v2/ecom/blue/straps/yel.png" }
    ) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta27: file(relativePath: { eq: "carry-me-v2/ecom/magenta/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta28: file(relativePath: { eq: "carry-me-v2/ecom/magenta/2.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta3: file(relativePath: { eq: "carry-me-v2/ecom/magenta/3.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta4: file(relativePath: { eq: "carry-me-v2/ecom/magenta/4.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta5: file(relativePath: { eq: "carry-me-v2/ecom/magenta/5.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta6: file(relativePath: { eq: "carry-me-v2/ecom/magenta/6.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta7: file(relativePath: { eq: "carry-me-v2/ecom/magenta/7.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta8: file(relativePath: { eq: "carry-me-v2/ecom/magenta/8.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta9: file(relativePath: { eq: "carry-me-v2/ecom/magenta/9.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta10: file(relativePath: { eq: "carry-me-v2/ecom/magenta/10.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Magenta11: file(relativePath: { eq: "carry-me-v2/ecom/magenta/11.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default CarryMe
