import queryString from 'query-string'
import React, { useEffect } from 'react'

const names = [
  'Red',
  'Dark-Pink',
  'Pink',
  'Orange',
  'Peach',
  'Yellow',
  'Dark-Green',
  'Green',
  'Mint',
  'Powder-Blue',
  'Blue',
  'Ultramarine-Blue',
  'French-Navy',
  'Lavender',
  'Dark-Purple',
  'White',
  'Grey',
  'Black',
  'Cream',
  'Pink',
  'Dusty-Pink',
  'Fuschia',
  'Mint',
  'Sky-Blue',
  'Blue',
  'Navy',
  'Red',
  'Plum',
  'Purple',
  'Lavender',
  'Forest',
  'Green',
  'Yellow',
  'White',
  'Grey',
  'Black',
]
const fonts = [
  'Block',
  'Wide',
  'Serif',
  'Casual',
  'Script',

  'Retro',
  'College',
  'Painted',
  'Signature',

  'zh-block',
  'zh-wide',
  'zh-serif',
  'zh-casual',
  'zh-script',
  'all-serif',
  'all-sans',
]
const locations = ['Back', 'Side', 'Top']

function usePersonalisationParams(
  searchQuery,
  setLabelData,
  setPersonaliseOpen
) {
  useEffect(() => {
    const search = queryString.parse(searchQuery)
    const valid =
      typeof search['text'] == 'string' &&
      search['text'].length > 0 &&
      typeof search['position'] == 'string' &&
      locations.includes(search['position']) &&
      typeof search['font'] == 'string' &&
      fonts.includes(search['font']) &&
      typeof search['color'] == 'string' &&
      names.includes(search['color']) &&
      (search['font'] !== 'Block' ||
        (typeof search['secondary'] == 'string' &&
          names.includes(search['secondary'])))

    if (valid) {
      setLabelData({
        font: search['font'],
        color: search['color'],
        shadow: search['secondary'],
        text: search['text'],
        position: search['position'],
      })
      setPersonaliseOpen(true)
    }
  }, [searchQuery])
}

export default usePersonalisationParams
